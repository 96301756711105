import React from 'react';
import { DwApp, Modal } from '@datadotworld/dw-utilities'




function fetchfile () {
    const params = new URLSearchParams(window.location.search)
    const dsid = params.get('dataset')
    const fileid = params.get('file')
    var xmlHttp = new XMLHttpRequest();
    const req = "/api/ddw/riotize?"+"dataset="+ dsid + "&file=" + fileid  ;

    xmlHttp.open( "GET", req, false ); // false for synchronous request
    xmlHttp.send( null );

    return xmlHttp.responseText;
//    return JSON.parse(xmlHttp.responseText);
}

export const DW_AUTH = {
  fetchDwUser,
  // These endpoints are defined in `ddw_oauth.js`
  loginUrl: '/api/oauth',
  logoutUrl: '/api/oauth/logout'
}
async function fetchDwUser() {
  const res = await fetch(`/api/ddw/user`)
  if (!res.ok) {
    return null;
  }
  const user = await res.json();
  return user;
}


const Format = () => {
  return (
    // If you want the app to display a "Login" button for you, you need to pass
    // a `fetchDwUser` function that can be called to load the authenticated user for you.
    <DwApp
      title="File Serializer"
	subtitle={"A formatted view of an RDF file in data.world"}
	dwAuth={DW_AUTH}
    >
	
	
	{
	    (
		<pre style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}>
		    {fetchfile()}
		</pre>
	    )
	}
    </DwApp>
  );
};
  
export default Format;
