import React, { useState } from 'react'
import { DwApp, Modal } from '@datadotworld/dw-utilities'
import { BrowserRouter as Router, Routes, Route}
from 'react-router-dom'
import Python from './pages/python'
import Format from './pages/format'



import './App.css'
import '@datadotworld/dw-utilities/dist/index.css'

/**
 * This is a function that fetches the current logged-in user, using the endpoint
 * defined in `ddw_api.js`
 */


// This really should work better

function fetchfile () {
    const params = new URLSearchParams(window.location.search)
    const dsid = params.get('dataset')
    const fileid = params.get('file')
    var xmlHttp = new XMLHttpRequest();
    const req = "/api/ddw/riotize?"+"dataset="+ dsid + "&file=" + fileid  ;

    xmlHttp.open( "GET", req, false ); // false for synchronous request
    xmlHttp.send( null );

    return xmlHttp.responseText;
//    return JSON.parse(xmlHttp.responseText);
}



async function fetchDwUser() {
  const res = await fetch(`/api/ddw/user`)
  if (!res.ok) {
    return null;
  }
  const user = await res.json();
  return user;
}

async function fetchDean() {
  const res = await fetch(`/api/ddw/dean`)
  if (!res.ok) {
    return null;
  }
  const dean = await res.json();
  return dean;
}

export const DW_AUTH = {
  fetchDwUser,
  // These endpoints are defined in `ddw_oauth.js`
  loginUrl: '/api/oauth',
  logoutUrl: '/api/oauth/logout'
}

function prettyPrint(object) {
  if (object === null) {
    return ''
  }

  const lines = Object.entries(object)
    .map(([key, value]) => `  "${key}": ${JSON.stringify(value)}`)
    .join('\n')

  return `{\n${lines}\n}`;
}

function App() {
    // Keep a copy of the last form submission.
    return (
    <Router>
	<Routes>
	    <Route exact path='/' element={<Format/>} />
	    <Route path='/format/' element={<Format/>} />
	    <Route path='/python/' element={<Python/>} />
	</Routes>
    </Router>
    );
    
}

export default App
