import React from 'react';

function fetchmessage () {
    const params = new URLSearchParams(window.location.search)
    const dsid = params.get('dataset')
    const fileid = params.get('file')
    
    const messages= { "xlsx":
		      `results = dw.query ('${dsid}', 'SELECT * FROM ${fileid}') # populate the table selected in the UI 
results_df = results.dataframe` }
    

    return messages.xlsx ;
    
}


const Python = () => {
  return (

      <pre>
	  {fetchmessage()}
      </pre>
  );
};
  
export default Python;

